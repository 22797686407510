<template>
  <div class="submit-wrapper">
    <div class="submit-header">
        <div class="submit-header-item">
            <div class="item-left">
              <div class="title">{{landInfo.farmName}}</div>
              <div class="space-info">#{{landInfo.landName}}（{{landInfo.areaSize}} ㎡）</div>
            </div>
            <div class="item-right">
              <van-icon name="wap-nav" size="28"/>
            </div>
        </div>
        <div class="submit-header-item" @click="showDays = true">
          <div class="item-left">
            <div class="remark">租赁周期</div>
            <div class="select">{{selectDaysTips}}</div>
          </div>
          <div class="item-right color-gray">
            <van-icon name="arrow" size="20"/>
          </div>
        </div>
    </div>
    <div class="submit-body">
      <div class="body-title">
        种子管理
      </div>
      <div class="body-wrapper">
            <div class="send-item" v-for="item in selectSeedArray" :key="item.seedId">
              <div class="seed-img">
                <van-image class="seed-van-image" :src="item.seedImg"></van-image>
                <span class="seed-name">{{item.seedName}}</span>
              </div>
              <div class="seed-space">
                {{item.num * item.minAreaSize}} ㎡
              </div>
            </div>
            <div class="add-seed" v-if="selectSeedArray.length <= 0">
              <van-button round plain type="info" color="#8fc42e" size="large" @click="toSeedListSelect">+&nbsp;添加种子</van-button>
            </div>
      </div>
    </div>
    <div class="submit-body">
      <div class="body-title">
        租赁人信息
      </div>
      <div class="body-wrapper">
        <van-cell-group>
          <van-field v-model="relName" label="姓名" placeholder="请输入租赁人姓名" />
          <van-field v-model="contact" label="联系电话" placeholder="请输入联系电话" />
          <van-field v-model="remark" label="备注" placeholder="有问题请告诉我" />
        </van-cell-group>
      </div>
    </div>
    <van-submit-bar :price="totalPrice" text-align="right" button-text="立即支付" button-color="#8fc42e" @submit="onSubmit">
      <template #default>
        <span class="price-list" @click="showPriceList=true">明细</span>
      </template>
    </van-submit-bar>
    <!--价格明细弹出框-->
    <van-popup v-model="showPriceList" round position="bottom">
      <div class="price-details-wrapper">
        <div class="price-list-item">
          <div class="price-remark">土地租赁费用</div>
          <div class="price-number">&yen;{{rentPrice}}</div>
        </div>
        <div class="price-list-item">
          <div class="price-remark">种子费用</div>
          <div class="price-number">&yen;{{seedTotalPrice}}</div>
        </div>
        <div class="price-list-item">
          <div class="price-remark">管理费</div>
          <div class="price-number">&yen;0</div>
        </div>
        <div class="price-list-item">
          <div class="price-remark">总计</div>
          <div class="price-number">&yen;{{totalPriceFloat}}</div>
        </div>
      </div>
    </van-popup>
    <!--租赁周期-->
    <van-popup v-model="showDays" round position="bottom">
      <van-picker
          show-toolbar
          :columns="columns"
          @cancel="showDays = false"
          @confirm="selectDays"
      />
    </van-popup>
  </div>
</template>

<script>

export default {
  name: "TySpaceSubmit",
  data(){
    return{
      showPriceList:false,
      selectLandId:0,
      selectDaysTips:'1个月（30天）',
      columns:['1个月（30天）','3个月（90天）','1年（365天）'],
      landInfo:{
        landId:0,
        landName:'',
        areaSize:'',
        saleDayPrice:0,
        farmName:''
      },
      relName:'',
      contact:'',
      remark:'',
      showDays:false,
      selectDayIndex:0,
      selectSeedArray:[]
    }
  },
  created() {
    this.selectLandId = this.$route.query.selectLandId;
    let selectSeedArray = this.$route.params.selectSeedArray;
    console.log('selectSeedArray',JSON.stringify(selectSeedArray));
    if(selectSeedArray){
      this.selectSeedArray = selectSeedArray;
    }
    this.$axios.get('/api/wxmp/farm/landDetails',{
      params:{
        landId:this.selectLandId
      }
    }).then((res) =>{
      console.log(JSON.stringify(res));
      this.landInfo = res.landInfo;
    }).catch((err) =>{
      console.log(JSON.stringify(err));
    })
  },
  computed:{
    totalPrice(){
      let days = 0;
      if(this.selectDayIndex == 0){
        days = 30;
      }else if(this.selectDayIndex == 1){
        days = 90;
      }else{
        days = 365;
      }
      return (this.landInfo.saleDayPrice * days) + Number(this.seedTotalPrice * 100);
    },
    rentPrice(){
      let days = 0;
      if(this.selectDayIndex == 0){
        days = 30;
      }else if(this.selectDayIndex == 1){
        days = 90;
      }else{
        days = 365;
      }
      return this.fen2yuan((this.landInfo.saleDayPrice * days));
    },
    totalPriceFloat(){
      return this.fen2yuan(this.totalPrice);
    },
    seedTotalPrice(){
      let seedTotalPrice = 0;
      this.selectSeedArray.forEach((item) =>{
        seedTotalPrice += (item.num * item.salePrice)
      })
      return this.fen2yuan(seedTotalPrice);
    },
  },
  methods:{
    onSubmit(){//立即下单支付
      if(!this.selectLandId){
        this.$toast.fail("未选择地块");
        return;
      }
      if(this.selectSeedArray.length <= 0){
        this.$toast.fail("未选择种子!");
        return;
      }
      if(!this.relName){
        this.$toast.fail("未输入租赁人姓名");
        return;
      }
      if(!this.contact){
        this.$toast.fail("未输入联系电话");
        return;
      }
      let seedArray = [];
      this.selectSeedArray.forEach(item =>{
        seedArray.push({
          seedId:item.seedId,
          num:item.num
        })
      })
      //订单
      this.$axios.postForm('/api/wxmp/farm/order/create',{
        landId:this.selectLandId,
        openid:this.$store.state.openid,
        relName:this.relName,
        contact:this.contact,
        userRemark:this.remark,
        rentIndex:this.selectDayIndex,
        seedArray:JSON.stringify(seedArray)
      }).then((res) =>{
        if(res.code === 0){
          let orderId = res.orderId;
          //去支付
          this.gotoPay(orderId);
        }else{
          this.$toast.fail(res.msg);
        }
      }).catch(err =>{

      })
    },
    gotoPay(orderId){
      this.$axios.postForm("/api/wxmp/farm/order/getWechatPayData",{
        orderId:orderId,
        openid:this.$store.state.openid
      }).then((res) =>{
        let that = this;
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
              "appId": res.data.appId,
              //公众号名称，由商户传入
              "timeStamp": res.data.timeStamp,
              //时间戳，自1970年以来的秒数
              "nonceStr": res.data.nonceStr,
              //随机串
              "package": res.data.package,
              "signType": res.data.signType,
              //微信签名方式：
              "paySign": res.data.paySign //微信签名
            },
            function(res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                that.$toast.success('支付成功');
                setTimeout(() =>{
                  that.$router.push("/ycty/tySpaceOrderList")
                },1000)
              }else{
                that.$toast.fail('支付失败');
                setTimeout(() =>{
                  that.$router.push("/ycty/tySpaceOrderList")
                },1000)
              }
            });
      }).catch((err) =>{
        this.$toast.fail('支付失败...');
      })
    },
    toSeedListSelect(){
      this.$router.push({name:'tySpaceSeedListSelect',query:{selectLandId:this.selectLandId,areaSize:this.landInfo.areaSize}})
    },
    selectDays(obj,index){
      this.showDays = false;
      this.selectDaysTips = obj;
      this.selectDayIndex = index;
    },
    fen2yuan(fen){
      var num = fen;
      num=fen*0.01;
      num+='';
      var reg = num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
      num=num.replace(reg,'$1');
      num = this.toDecimal2(num)
      return num
    },
    toDecimal2(x){
      var f = parseFloat(x);
      if (isNaN(f)) {
        return false;
      }
      f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf('.');
      if (rs < 0) {
        rs = s.length;
        s += '.';
      }
      while (s.length <= rs + 2) {
        s += '0';
      }
      return s;
    }
  }
}
</script>

<style lang="scss" scoped>
  .submit-wrapper{
    background-color: #f5f5f5;
    height: 100%;
    padding: 10px;
  }
  .submit-header{
    background-color: #fff;
    color: $--color-primary;
    border-radius: 10px;
  }
  .submit-header-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    border-bottom: 1px solid  #f5f5f5;
  }
  .item-left .remark{
    font-size: 14px;
    color: #888;
    padding-bottom: 10px;
  }
  .item-left .select{
    color: #000000;
    font-size: 16px;
  }
  .item-left .title{
    font-size: 18px;
    padding-bottom: 10px;
  }
  .item-right.color-gray{
    color: #888;
  }
  .submit-body{
    background-color: #fff;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;
  }
  .body-title{
    font-size: 18px;
    font-weight: bolder;
    color: $--color-primary;
    padding: 10px 0;
    border-bottom: 1px solid #f5f5f5;
  }
  .send-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .seed-img .seed-name{
    vertical-align: middle;
    color: #888;
  }
  .seed-van-image{
    width: 50px;
    height: 50px;
    padding: 5px;
    vertical-align: middle;
  }
  .add-seed{
    text-align: center;
    padding-top: 10px;
  }
  .add-seed .van-button{
    height: 40px;
    width: 200px;
  }
  .price-list{
    color: #666;
    font-size: 12px;
  }
  .price-details-wrapper{
    padding: 10px 10px 80px 10px;
  }
  .price-list-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    padding: 10px 0;
  }
  .price-list-item .price-remark{
    color: #666;
  }
</style>
