<template>
    <van-list
        class="ui-van-page is-light"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
        <div class="home-search-bar">
            <div class="home-search-bar__inner">
                <div class="home-search-bar__area">
                    <div>
                        <span>南山区</span>
                        <van-icon name="arrow-down"/>
                    </div>
                </div>
                <van-search
                    class="flex-item"
                    v-model="keyword"
                    disabled
                    shape="round"
                    placeholder="请输入商家、商品名称"
                    background="transparent"
                    @search="onSearch"
                    @cancel="onCancel"
                />
                <div class="home-search-bar__scan">
                    <van-icon name="scan"/>
                </div>
            </div>
        </div>

        <div class="my-swipe gap-mb">
            <van-swipe :autoplay="3000" indicator-color="white" height="140">
                <van-swipe-item v-for="item of swipeList" :key="item.id">
                    <img class="my-swipe-img" :src="item.src">
                </van-swipe-item>
            </van-swipe>
        </div>

        <div class="gap-mb-xs text-light">附近商家</div>
        <div class="bg-white">

            <van-sticky :offset-top="54">
                <van-dropdown-menu>
                    <van-dropdown-item :title="activeTitle">
                        <van-tree-select
                            :items="items"
                            :active-id.sync="activeId"
                            :main-active-index.sync="activeIndex"
                            @click-nav="clickNav"
                            @click-item="clickItem"
                        />
                    </van-dropdown-item>
                    <van-dropdown-item v-model="sortValue" :options="sortMenu" />
                    <van-dropdown-item title="筛选" />
                </van-dropdown-menu>
            </van-sticky>

            <van-card
                v-for="(item, index) of dataList"
                :key="index"
                price="2.00"
                desc="描述信息"
                title="商品标题"
                thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
                @click="linkList"
            >
                <template #tags>
                    <van-tag plain type="danger">满￥88减￥3</van-tag>
                </template>
            </van-card>
        </div>
    </van-list>
</template>

<script>
export default {
    name: "Shop",
    data() {
        return {
            keyword: '',
            swipeList: [],
            loading: false,
            finished: false,
            pageNo: 1,
            pageSize: 16,
            dataList: [],
            sortValue: 1,
            sortMenu: [
                { text: '智能排序', value: 1 },
                { text: '销售数量最高', value: 2 },
                { text: '离我最近', value: 3 },
                { text: '起送价最低', value: 4 },
                { text: '评分最高', value: 5 },
                { text: '最新发布', value: 6 },
            ],
            items: [
                {
                    text: '浙江',
                    children: [{
                        id: 1,
                        text: '南山',
                    },{
                        id: 3,
                        text: '福田',
                    }]
                },
                {
                    text: '江苏',
                    children: [{
                        id: 4,
                        text: '青羊',
                    },{
                        id: 5,
                        text: '万维网',
                    }]
                },
            ],
            activeId: 1,
            activeIndex: 0,
            activeTitle: '全部',
        }
    },
    methods: {
        onSearch() {
            console.log(12)
        },
        onCancel() {
            console.log(233)

        },
        getSwipeList() {
            this.swipeList = [{
                id: 1,
                src: 'http://www.gx114.cc/upload/adver/000/000/001/5a8261202609a111.jpg',
                link: ''
            }, {
                id: 2,
                src: 'http://www.gx114.cc/upload/adver/000/000/001/5a826114b6586802.jpg',
                link: ''
            }, {
                id: 3,
                src: 'http://www.gx114.cc/upload/adver/000/000/001/5a8261324cabf787.jpg',
                link: ''
            }]
        },
        getList() {
            setTimeout(() => {
                let arr = []
                for(let i=1; i<17; i++) {
                    arr.push({
                        id: (new Date()).getTime() + i,
                    })
                }

                this.dataList = this.dataList.concat(arr)

                this.loading = false
                if(this.pageNo >= 4) {
                    this.finished = true
                }
            })
        },
        reload() {
            this.pageNo = 1
        },
        onLoad() {
            this.pageNo++
            this.getList()
        },
        clickNav(index) {
            // this.activeIndex = index
        },
        clickItem(index) {
            // this.activeId = index
        },
        linkList() {
            this.$router.push('/shopList')
        }
    },
    mounted() {
        this.getSwipeList()
        this.getList()
    }
}
</script>

<style lang="scss" scoped>
.my-swipe-img {
    width: 100%;
    height: 140px;
    object-fit: cover;
}

.panel1 {
    background-color: #fff;
    padding: 12px;
}

.my-navs {
    display: flex;

    &__item {
        flex: 0 0 20%;
        max-width: 20%;
        margin-top: $gap-size;
    }
}

.goods-nav-scroll {
    overflow-x: auto;
}

.goods-nav {
    display: flex;

    &__title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 3px;
    }

    &__sub {
        color: #787878;
        padding: 1px 8px;
        border-radius: 8px;
        font-size: 12px;
    }

    &__item {
        white-space: nowrap;
        padding: 12px;
        text-align: center;

        &.is-active {
            color: #01c1aa;
        }

    }
}

.goods-nav__item.is-active .goods-nav__sub {
    color: #fff;
    background-color: #01c1aa;
}
</style>