<template>
  <div class="seed-list-wrapper">
    <div class="seed-item" v-for="(item) in seedList" :key="item.seedId">
      <div class="seed-left">
        <van-image class="image-seed" :src="item.seedImg"></van-image>
      </div>
      <div class="seed-right">
        <p class="seed-name">
          {{item.seedName}}
        </p>
        <p class="seed-remark">
          最低种植：{{item.minAreaSize}}㎡;产量：{{item.outPut}}kg/㎡
        </p>
        <div class="buy-container">
          <div class="price">&yen;{{item.salePriceFloat}}</div>
          <div class="buy-num">
            <van-stepper v-model="item.num" min="0" max="8" />
          </div>
        </div>
      </div>
    </div>
    <van-submit-bar button-text="确认" :price="seedTotalPrice" label="种子合计"  button-color="#8fc42e" @submit="onConfirm">
      <div class="select-seed">
        <p class="select-tips">选择种子</p>
        <p class="selected">已选：{{selectedAreaSize}}/{{areaSize}}㎡</p>
      </div>
    </van-submit-bar>
  </div>
</template>

<script>
export default {
  name: "TySpaceSeedListSelect",
  data(){
    return{
      selectLandId:0,
      seedList:[],
      areaSize:0
    }
  },
  computed:{
    selectedAreaSize(){
      let areaSize = 0;
      this.seedList.forEach((item) =>{
        if(item.num > 0){
          areaSize += item.num * item.minAreaSize
        }
      })
      return areaSize;
    },
    seedTotalPrice(){
      let seedTotalPrice = 0;
      this.seedList.forEach((item) =>{
        if(item.num > 0){
          seedTotalPrice += item.num * item.salePrice;
        }
      })
      return seedTotalPrice;
    }
  },
  created() {
    this.selectLandId = this.$route.query.selectLandId;
    this.areaSize = this.$route.query.areaSize;
    this.$axios.get('/api/wxmp/farm/seedList',{
      params:{
        branchId:this.$store.getters.getLocation.branchId
      }
    }).then((res) =>{
      console.log(JSON.stringify(res));
      this.seedList = res.seedList;
    }).catch((err) =>{
      console.log(JSON.stringify(err));
    })
  },
  methods:{
    onConfirm(){
      let selectSeedArray = [];
      this.seedList.forEach((item) =>{
        if(item.num > 0){
          selectSeedArray.push(item)
        }
      })
      if(selectSeedArray.length === 0){
        this.$toast.fail('未选择种子');
        return;
      }
      this.$router.push({
        name:'tySpaceSubmit',
        query:{
          selectLandId:this.selectLandId
        },
        params:{
          selectSeedArray:selectSeedArray
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  p{
    margin: 0;
    padding: 0;
  }
  .seed-list-wrapper{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    overflow-y: scroll;
    padding: 10px;
  }
  .seed-item{
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
  }
  .seed-left{
    width: 100px;
    height: 100px;
    padding: 5px;
    border-radius: 5px;
    overflow: hidden;
  }
  .seed-right{
    padding: 5px;
    flex:1;
  }
  .seed-right .seed-name{
    font-size: 16px;
    color: black;
    font-weight: bolder;
  }
  .seed-right .seed-remark{
    padding: 5px 0;
    color: #888;
  }
  .buy-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }
  .buy-container .price{
    font-size: 16px;
    color: red;
  }
  .select-tips{
    color: black;
    font-size: 16px;
    font-weight: bolder;
  }
  .select-seed .selected{
    color: #888;
  }
</style>
