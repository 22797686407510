<template>
  <div class="join-container">
    <img :src="logo" alt="">
    <p><span>刘天盛</span></p>
    <p><span>13827751686</span></p>
    <p><span>广东渔菜共生科技有限公司</span></p>
  </div>
</template>

<script>
export default {
  name: "JoinUs",
  data(){
    return{
      logo:require('../../assets/ycty-logo.jpg')
    }
  }
}
</script>

<style scoped>
  .join-container{
    width: 100%;
    text-align: center;
    padding-top: 30px;
  }
  .join-container img{
    width: 150px;
    height: 150px;
  }
  .join-container{
    color: black;
    font-size: 16px;
    font-weight: bolder;
  }
</style>
