import { render, staticRenderFns } from "./TySpaceSubmit.vue?vue&type=template&id=667ee312&scoped=true&"
import script from "./TySpaceSubmit.vue?vue&type=script&lang=js&"
export * from "./TySpaceSubmit.vue?vue&type=script&lang=js&"
import style0 from "./TySpaceSubmit.vue?vue&type=style&index=0&id=667ee312&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "667ee312",
  null
  
)

export default component.exports