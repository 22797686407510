<template>
  <div>
    <van-list
        class="van-list"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <div class="order-list-container">
        <div class="order-item" v-for="(item) in orderList" :key="item.orderNo">
          <div class="order-item-header">
            <p class="order-no">订单号：<span>{{item.orderNo}}</span></p>
            <p class="order-no-pay" :class="{'active':item.orderStatus === 1}"  >{{orderStatus[item.orderStatus]}}</p>
          </div>
          <div class="order-branch">
            <p class="branch-name">{{item.farmName}}</p>
            <van-card
                class="van-card"
                :thumb="item.farmImg"
            >
              <template #title>
                <div class="order-land-list">
                  <div class="order-land-item" v-for="landItem in item.landArray" :key="landItem.landId">
                    <span class="land-name">{{landItem.landName}}</span>（{{landItem.landAreaSize}}㎡）
                  </div>
                </div>
              </template>
              <template #price>
                <div class="order-seed-list" v-for="seedItem in item.seedArray" :key="seedItem.landId">
                  <div class="order-seed-item">
                    {{seedItem.seedName}} x {{seedItem.buyNum}} （{{seedItem.totalAreaSize}}㎡）
                  </div>
                </div>
              </template>
            </van-card>
          </div>
          <div class="order-item-footer">
            <div class="order-total-price">
              总金额：<span class="price bolder">&yen;{{item.totalPrice}}</span>
            </div>
            <div class="footer-button">
              <van-button color="#999" size="mini" @click="cancelOrder(item.orderId)" v-if="item.orderStatus === 0">取消订单</van-button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <van-button  color="#8fc42e" size="mini" @click="gotoPay(item.orderId)" v-if="item.orderStatus === 0">去支付</van-button>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
export default {
  name: "OrderList",
  data(){
    return {
      loading:true,
      finished:false,
      pageNo:1,
      limit:5,
      openid:this.$store.state.openid,
      orderList:[],
      orderStatus:[
        '未支付',
        '已付款',
        '已取消'
      ],
      queryOrderStatus:-1
    }
  },
  created() {
    let queryOrderStatus = this.$route.query.orderStatus;
    if(queryOrderStatus > -1){
      this.queryOrderStatus = queryOrderStatus
    }
    this.getList();
  },
  methods:{
    onLoad(){
      this.getList();
    },
    cancelOrder(orderId){
      this.$axios.postForm("/api/wxmp/farm/order/cancel",{
        orderId:orderId,
        openid:this.$store.openid
      }).then((res) =>{
        this.$toast.success("取消成功");
        setTimeout(() =>{
          window.location.reload();
        },1000)
      }).catch((err) =>{
        this.$toast.fail('取消失败');
      });
    },
    gotoPay(orderId){
      this.$axios.postForm("/api/wxmp/farm/order/getWechatPayData",{
        orderId:orderId,
        openid:this.openid
      }).then((res) =>{
        let that = this;
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
              "appId": res.data.appId,
              //公众号名称，由商户传入
              "timeStamp": res.data.timeStamp,
              //时间戳，自1970年以来的秒数
              "nonceStr": res.data.nonceStr,
              //随机串
              "package": res.data.package,
              "signType": res.data.signType,
              //微信签名方式：
              "paySign": res.data.paySign //微信签名
            },
            function(res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                that.$toast.success('支付成功');
                setTimeout(() =>{
                  window.location.reload();
                },1000)
              }else{
                that.$toast.fail('支付失败');
              }
            });
      }).catch((err) =>{
        this.$toast.fail('支付失败...');
      })
    },

    getList(){
      this.$axios.get("/api/wxmp/farm/order/list",{
        params:{
          openid:this.openid,
          page:this.pageNo,
          limit:this.limit,
          orderStatus:this.queryOrderStatus
        }
      }).then((res) =>{
        console.log("res",JSON.stringify(res))
        this.orderList = this.orderList.concat(res.page.list);
        this.loading =false;
        if(res.page.totalPage <= this.pageNo){
          this.finished = true;
          console.log('finished');
        }
        this.pageNo = this.pageNo + 1;
      }).catch((err) =>{
        console.log("err",err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
p{
  margin: 0;
  padding: 0;
}
.order-list-container{
  background-color: rgb(237, 237, 237);
  /*padding-bottom: 10px;*/
  width: 100%;
  height: 100%;
}
.order-no{
  color: black;
}
.order-no span{
  color: #666;
}
.order-no-pay.active{
  color: $--color-primary;
}
.order-item{
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
}
.order-item-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(237, 237, 237);
  padding: 5px 0;
}
.order-no-pay{
  color: #666;
}
/*.order-item +.order-item{*/
/*  margin-top: 10px;*/
/*  border-top: 1px solid #f5f5f5;*/
/*}*/
.van-card{
  border-bottom: 1px solid rgb(237, 237, 237);
  border-top: 1px solid rgb(237, 237, 237);
}
.price{
  color: red;
}
.price.bolder{
  font-weight: bolder;
}
.goods-name{
  color: black;
  padding: 5px 0;
}
.goods-standard span{
  padding-right: 5px;
}
.buy-num{
  color: #666;
  margin-left: 10px;
}
.order-item-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  font-size: 14px;
}
.order-branch{
  margin: 10px 0;
  color: black;
}
.branch-name{
  margin-left: 10px;
  font-weight: bolder;
  padding-bottom: 5px;
}
.van-card__thumb{
  width: 140px;
  height: 88px;
}
.order-land-list{
  padding: 5px 0;
}
.order-land-item .land-name{
  color: black;
  font-size: 16px;
}
.order-land-item{

}
</style>
