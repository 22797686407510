<template>
  <div class="details-container clearfix">
    <div class="details-img">
      <img :src="farmInfo.farmImg" alt="">
    </div>
    <div class="details-info gap-mb">
      <div class="p-price">
        <div class="price-share">
            <span class="price-sign">&yen;</span>
            <span class="price-value">{{farmInfo.minSalePrice}}</span>
            <span class="price-note">元起</span>
        </div>
        <div class="share">分享<van-icon name="share-o" class="icon" /></div>
      </div>
      <div class="details-title">
          <div class="title">{{farmInfo.farmName}}</div>
          <div class="sub-title">{{farmInfo.subTitle}}</div>
      </div>
      <div class="details-tag">
<!--        <span class="item-tag bgc-warn">购物送积分</span>-->
        <span class="item-tag left-10" v-if="farmInfo.tag">{{farmInfo.tag}}</span>
      </div>
      <div class="location">
        <div>
          <van-icon name="location-o" class="location-icon" /> <span class="local-name">{{farmInfo.location}}</span><van-icon name="arrow" class="direct-icon" />
        </div>
        <div class="buy-num">
          1900条出租记录
        </div>
      </div>
      <div class="item-wrapper">
        <div class="item-title">
          <div class="item-tips">选择地块</div>
          <div class="space-less">剩余{{farmInfo.freeLandNum}}/{{farmInfo.totalLandNum}}</div>
        </div>
        <div class="space-tips">
          <div class="space-tips-item">
            <span class="tips-color color-enable"></span>
            <span class="tips-word">可选</span>
          </div>
          <div class="space-tips-item">
            <span class="tips-color color-select"></span>
            <span class="tips-word">选中</span>
          </div>
          <div class="space-tips-item">
            <span class="tips-color color-saled"></span>
            <span class="tips-word ">已租</span>
          </div>
        </div>
        <van-radio-group v-model="selectLandId">
          <div class="space-list">
            <div class="space-item" v-for="(item) in landList" :key="item.landId">
                <div class="space-show" :class="[{'checked':selectLandId === item.landId},{'disabled':item.landStatus === 1}]">
                  {{item.areaSize}}㎡
                </div>
                <div class="space-checked">
                  <van-radio checked-color="#8fc42e" :disabled="item.landStatus === 1" :name="item.landId" icon-size="15px"></van-radio>
                </div>
            </div>
        </div>
        </van-radio-group>
      </div>
    </div>
    <!--租赁周期-->
    <div class="item-wrapper" @click="show = true">
      <div class="item-title">
        <div class="item-tips">租赁周期</div>
        <div class="rent-select">
          <span class="rent-days">1个月</span>
          <van-icon name="arrow"  size="16"/>
        </div>
      </div>
    </div>

    <!--种子列表-->
    <div class="item-wrapper">
      <div class="item-title">
        <div class="item-tips">种子列表</div>
        <div class="seed-select">
          <span class="seed-days">查看更多</span>
          <van-icon name="arrow"  size="16"/>
        </div>
      </div>
      <!--种子商品-->
      <van-grid :border="false" :column-num="2">
        <van-grid-item v-for="(item) in seedList" :key="item.seedId">
          <div class="seed-info-wrapper">
            <div class="seed-img">
              <van-image :src="item.seedImg" />
            </div>
            <div class="seed-info">
              <p class="seed-name">{{item.seedName}}</p>
              <p class="seed-get">预估产量：{{item.outPut}}kg/㎡</p>
              <p class="seed-price">&yen;{{item.salePrice}}</p>
            </div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>

    <div class="ui-panel">
      <div class="ui-panel-head">
        <span class="ui-panel-head__title">详细说明</span>
      </div>
      <div class="ui-panel-body">
        <div class="article-content" v-html="farmInfo.content">

        </div>
      </div>
    </div>
    <!--租赁周期-->
    <van-popup v-model="show" round position="bottom">
      <van-picker
          show-toolbar
          :columns="columns"
          @cancel="show = false"
          @confirm="onFinish"
      />
    </van-popup>
    <!--立即租赁-->
<!--    <van-submit-bar button-text="立即租赁" button-color="#8fc42e" @submit="onSubmit" >-->
<!--      <slot name="default">-->

<!--      </slot>-->
<!--    </van-submit-bar>-->
    <van-goods-action>
      <div style="width: 60px">
<!--        <van-goods-action-icon icon="home-o" text="首页" to="/ycty/lifeHome"/>-->
      </div>
      <div class="submit-wrapper">
        <van-goods-action-button
            type="default"
            color="#8fc42e"
            text="立即租赁"
            @click="onSubmit"
        />
      </div>
    </van-goods-action>
  </div>
</template>

<script>
export default {
  name: "TySpaceDetails",
  data(){
    return {
      selectLandId:0,
      show:false,
      cascaderValue:'',
      columns:['1个月（30天）','3个月（90天）','1年（365天）'],
      farmId:0,
      farmInfo:{
        farmId:0,
        farmName:'',
        farmImg:'',
        subTitle:'',
        minSalePrice:'',
        location:'',
        tag:'',
        content:'',
        freeLandNum:0,
        totalLandNum:0
      },
      landList:[],
      seedList:[]
    }
  },
  created() {
    this.farmId = this.$route.query.farmId;
    console.log('farmId',this.farmId );
    console.log('this.$route.query',this.$route.query);
    this.$axios.get('/api/wxmp/farm/details',{
      params:{
        branchId:this.$store.getters.getLocation.branchId,
        farmId:this.farmId
      }
    }).then((res) =>{
      console.log(JSON.stringify(res));
      this.farmInfo = res.farmInfo;
      this.landList = res.landArray;
      this.seedList = res.seedArray;
    }).catch((err) =>{
      console.log(JSON.stringify(err));
    })
  },
  methods:{
    onFinish(selectObj){
      this.show = false;
    },
    onSubmit(){
      if(this.selectLandId <= 0){
        this.$toast.fail('请选择地块!');
        return;
      }
      this.$router.push({name:"tySpaceSubmit",query:{selectLandId:this.selectLandId}})
    }
  }
}
</script>

<style lang="scss" scoped>
  p{
    margin: 0;
    padding: 0;
  }
  .details-container{
    background-color: #f5f5f5;
    min-height: calc(100% - 50px);
  }
  .details-img{
    width: 100%;
    background-color: #fff;
  }
  .details-img img{
    width: 100%;
    height: 180px;
  }
  .details-info{
    margin-top: 5px;
    background-color: #fff;
    padding: 10px;
  }
  .p-price{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  .price-sign{
    color: red;
  }
  .price-value{
    color: red;
    padding-right: 5px;
    font-size: 18px;
    font-weight: bolder;
  }
  .share,.icon{
    vertical-align: middle;
    background-color: $--color-primary;
    padding: 3px 5px;
    border-radius: 10px;
    color: #fff;
  }
  .details-title{
  }
  .title{
    color: black;
    font-size: 14px;
    font-weight: bolder;
    margin-bottom: 5px;
  }
  .sub-title{
    font-size: 12px;
    color: #666;
  }
  .left-10{
    margin-left: 10px;
  }
  .details-tag{
    padding-top: 10px;
  }
  .bgc-warn{
    background-color: red;
  }
  .location{
    vertical-align: middle;
    padding-top: 10px;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #fafafa;
  }
  .location-icon{
    vertical-align: middle;
  }
  .local-name{
    vertical-align: middle;
  }
  .direct-icon{
    vertical-align: middle;
    margin-left: 10px;
  }
  .buy-num{
    color: #666;
    font-size: 12px;
  }
  .space-less{
    color: #666;
    font-size: 12px;
  }
  .space-tips{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .space-tips-item{
    padding: 0 10px;
  }
  .tips-color{
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
  }
  .tips-color.color-enable{
    background-color: #9C661F
  }
  .tips-color.color-select{
    background-color: #8fc42e;
  }
  .tips-color.color-saled{
    background-color: #d0d0d0d0
  }
  .space-list{
    margin-top: 10px;
    width: 100%;
    height: 120px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  .space-list::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .space-item{
    padding: 10px;
    text-align: center;
    flex-direction: column;
    display: inline-block;
    white-space: normal;
    position: relative;
  }
  .space-show{
    width: 2em;
    height: 80px;
    background-color: #9C661F;
    //writing-mode:tb-rl;
    text-align: center;
    //line-height: 30px;
    padding-top: 20px;
    opacity: .8;
  }
  .space-show.disabled{
    background-color: #d0d0d0d0;
  }
  .space-show.checked{
    opacity: 1;
  }
  .space-checked{
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .rent-select{
    color: #000000;
    font-size: 14px;
  }
  .rent-days{
    vertical-align: middle;
  }
  .van-icon-arrow{
    vertical-align: middle;
  }

  .item-wrapper{
    margin: 5px 0;
    background-color: #fff;
    padding: 10px;
  }
  .item-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .item-tips{
    color: black;
    font-size: 14px;
    font-weight: bolder;
  }
  .seed-select{
    color: #666;
    font-size: 12px;
  }
  .seed-days{
    vertical-align: middle;
  }
  .seed-info-wrapper{
    display: flex;
    justify-items: center;
    align-items: center;
  }
  .seed-img{
    width: 50px;
    height: 50px;
    padding: 5px;
  }
  .seed-info p{
    padding: 1px 0;
  }
  .seed-info{

  }
  .seed-name{
    color: #000000;
  }
  .seed-get{
    color: #666;
    font-size: 12px;
  }
  .seed-price{
    color: red;
  }
  .submit-wrapper{
    width: 100px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
</style>
